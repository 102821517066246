import React from "react";

function PrathistanReducer(state, action) {
  switch (action.type) {
    case "events":
      return { ...state, events: action.payload };

    case "event":
      return { ...state, event: action.payload };

    case "single_event":
      return { ...state, singleEvent: action.payload };

    default:
      return state;
  }
}

export default PrathistanReducer;
