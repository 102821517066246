import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";

// Define your icon's SVG path
const iconSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="%23ff0000" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>`;

// Create a new Leaflet icon using the SVG data
const customIcon = new L.Icon({
   iconUrl: `data:image/svg+xml;utf8,${encodeURIComponent(iconSvg)}`,
   iconSize: [30, 30], // size of the icon
});

function Map() {
   const position = [26.653755, 87.989554];

   return (
      <div className="h-[600px] w-10/12 mx-auto">
         <MapContainer
            className="h-full"
            center={position}
            zoom={14}
            scrollWheelZoom={false}
         >
            <TileLayer
               attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
               url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position} icon={customIcon}>
               <Popup>यशोदा निरौला स्मृति प्रतिष्ठान नेपाल</Popup>
            </Marker>
         </MapContainer>
      </div>
   );
}

export default Map;
