import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Header from "../Header";
import Home from "../Home";
import Page_not_found from "../Page_not_found";
import Events_page from "../pages/Events_page";
import Footer from "../Footer";
import Single_Event from "../pages/Single_Event";

//events


export default function RouterPage() {
   const location = useLocation();
   return (
      <>
         <Header />
         <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/events" element={<Events_page />} />
            <Route path="/event/:id" element={<Single_Event />} />
            <Route path="/*" element={<Page_not_found />} />
         </Routes>
         <Footer />
      </>
   );
}
