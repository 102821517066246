import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import PrathistanContext from "../context/PrathistanContext";
import Naruto from "../../assets/img/naruto.png";

function Single_Event() {
   const { events } = useContext(PrathistanContext);
   const { id } = useParams();
   const [event, setEvent] = useState();
   useEffect(() => {
      setEvent(events.find((eve) => eve._id === id));
   }, [events]);
   console.log(event);

   // const addEventHeader = () => {
   //    const eventHeader = document.querySelector(".event-header");
   //    if (eventHeader) {
   //       eventHeader.style.backgroundImage = `url('${Naruto}')`;

   //       document.querySelector(".paragraph").innerHTML = event?.description;
   //    }
   // };
   // console.log(event);
   return (
      <div className="single-event">
         <div
            className="relative"
            style={{
               backgroundImage: `url(${event?.image})`,
               height: "400px",
               backgroundSize: "cover",
            }}
         >
            <div className="absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,0.4)]">
               <div className="text mt-5">
                  <h1 className="heading text-center z-20 text-white">
                     {event?.title}
                  </h1>
               </div>
            </div>
         </div>
         <div className="event-body">
            <div className="text">
               <p className="heading">{event?.title}</p>
               <div className="images">
                  <img
                     src={event?.image}
                     style={{
                        height: "200px",
                        width: "200px",
                     }}
                  />
               </div>
               <div className="paragraph mt-5">{event?.description}</div>
            </div>
         </div>
      </div>
   );
}

export default Single_Event;
