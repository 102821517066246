import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function Scroll() {
  //this smooth scrolls

  const navigate = useNavigate();

  useEffect(() => {
    const navs = document.querySelectorAll("#header a");
    navs.forEach((element) => {
      element.addEventListener("click", async (e) => {
        await navigate("/");

        const section = e.target.classList[1]; // this gets the id of the section to scroll down to
        const scrollPosition = document
          .getElementById(section)
          .getBoundingClientRect().top; //top position of the selected element
        const currentPosition = document.documentElement.scrollTop; //current position of the window
        document.documentElement.scrollTop =
          currentPosition + scrollPosition - 80;
      });
    });
  }, []);

  //scroll event ..this hides navbar  //this highlights the nav element
  let lastScrollTop = 0;
  document.addEventListener("scroll", function () {
    //for hiding the navbar
    let st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop) {
      document.querySelector("nav").classList.add("hide-navbar");
    } else if (st < lastScrollTop) {
      document.querySelector("nav").classList.remove("hide-navbar");
    }
    lastScrollTop = st <= 0 ? 0 : st;

    //for highlighting the nav element
    // const currentPosition = document.documentElement.scrollTop; //current position of the window
    // const navs = document.querySelectorAll("#header a");
    // navs.forEach((element) => {
    //   const elementTop = element.getBoundingClientRect().top;
    //   // console.log(elementTop);
    //   if (elementTop < currentPosition) element.classList.add("active");
    // });
  });
}

export default Scroll;
