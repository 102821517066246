import { React } from "react";
import naruto from "../assets/img/naruto.png";
import gallery1 from "../assets/img/gallery1.jpeg";
import gallery2 from "../assets/img/gallery2.jpeg";
import gallery3 from "../assets/img/gallery3.jpeg";
import gallery4 from "../assets/img/gallery4.jpeg";
import gallery5 from "../assets/img/gallery5.jpeg";
import gallery6 from "../assets/img/gallery6.jpeg";
import gallery7 from "../assets/img/gallery7.jpeg";
import gallery8 from "../assets/img/gallery8.jpeg";
import gallery9 from "../assets/img/gallery9.jpeg";

function Gallery() {
   return (
      <div id="gallery">
         <div className="section-title">Gallery</div>
         <div className="images">
            <div className="img img-1 img-shading">
               <img src={gallery1} alt="" />
            </div>
            <div className="img img-2">
               <img src={gallery2} alt="" />
            </div>
            <div className="img img-3">
               <img src={gallery3} alt="" />
            </div>
            <div className="img img-4">
               <img src={gallery4} alt="" />
            </div>
            <div className="img img-5">
               <img src={gallery5} alt="" />
            </div>
            <div className="img img-6">
               <img src={gallery6} alt="" />
            </div>
            <div className="img img-7">
               <img src={gallery7} alt="" />
            </div>
            <div className="img img-8">
               <img src={gallery8} alt="" />
            </div>
         </div>
      </div>
   );
}

export default Gallery;
