import React from "react";
import logo from "../assets/img/logo.jpg";

function Footer() {
   return (
      <footer>
         <div className="contain pt-5 mt-5 row gap-1">
            <div className="container-1 col-xxl-6 col-xl-6  col-xl-6 col-md-12 col-sm-12 col-xs-12 ">
               <div className="title">
                  <img src={logo} alt="" />
                  <h4>यशोदा निरौला स्मृति प्रतिष्ठान नेपाल</h4>
               </div>
               We are here for social service, in the remembrance of Yashoda Niraula.
            </div>

            <div className="container-2 col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
               <h5></h5>
            </div>
            <div className="container-3 col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xs-12">
               <h5>Contact Information</h5>
               <h5>+977-9801444912</h5>
            </div>
         </div>
         <div className="last-footer">
            <p>
               © 2023 <a href="/">यशोदा निरौला स्मृति प्रतिष्ठान नेपाल</a>. All
               Rights Reserved.
            </p>
         </div>
      </footer>
   );
}

export default Footer;
