import React from "react";
import Event_card from "./shared/Event_card";
import { Link } from "react-router-dom";
import PrathistanContext from "./context/PrathistanContext";
import { useContext } from "react";

function Events() {
   let { events } = useContext(PrathistanContext);

   events = events.slice(0, 4);
   return (
      <div id="events" className="w-4/5 mx-auto">
         <div className="section-title">Latest Events</div>
         <div className="grid grid-cols-12 gap-y-5 mt-5 ">
            {events?.map((element) => {
               return (
                  <div
                     className="col-start-2 col-span-10 mx-auto sm:col-span-8 md:col-span-6 lg:col-span-5 xl:col-span-3"
                     key={element._id}
                  >
                     <div className="w-full translate ">
                        <Event_card data={element} key={element.id} />
                     </div>
                  </div>
               );
            })}
         </div>
         <div className="container-btn">
            <Link to="/events" className="event-btn">
               <button className="btn ms-auto">view more</button>
            </Link>
         </div>
      </div>
   );
}

export default Events;
