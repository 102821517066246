import React from "react";
import naruto from "../../assets/img/naruto.png";
import { Link } from "react-router-dom";

function Event_card({ data }) {
   let description = data.description;
   let words = description.split(" ");
   if (words.length > 10) {
      description = words.slice(0, 5).join(" ") + "...";
   }
   return (
      <div className="card shadow-lg event-card mx-auto w-[200px] h-[500px] sm:h-[230px] sm:w-[230px] md:w-[300px] md:h-[300px]">
         <div className="h-[200px] w-[200px] sm:h-[230px] sm:w-[230px] md:w-[300px] md:h-[300px]">
            <img
               src={data?.image}
               className="card-img-top object-cover "
               alt="..."
            />
         </div>
         <div className="card-body ">
            <p className="card-text border p-2 min-h-16">{description}</p>
         </div>
         <div className="card-footer">
            <Link to={`/event/${data._id}`}>
               <span></span> Read More
            </Link>
         </div>
      </div>
   );
}

export default Event_card;
