import React from "react";
import Event_card from "../shared/Event_card";
import { useContext } from "react";
import PrathistanContext from "../context/PrathistanContext";
function Events_page() {
   const { events } = useContext(PrathistanContext);

   return (
      <div className="" id="event-page">
         <header>
            <p>EVENTS</p>
         </header>
         <div className="grid grid-cols-12 mt-10">
            {events.map((element) => {
               return (
                  <div
                     className="  col-start-2 col-span-10  sm:col-span-6 md:col-span-5 lg:col-span-4 xl:col-span-3"
                     key={element._id}
                  >
                     <Event_card data={element} />
                  </div>
               );
            })}
         </div>
      </div>
   );
}

export default Events_page;
