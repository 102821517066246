import React from "react";
import netflix from "../assets/img/naruto.png";

function Hero() {
   return (
      <div className="hero">
         <div className="text row">
            <h1 className="title">यसोदा निरौला स्मृति प्रतिष्ठान।</h1>
            <p className=" ">
               यो पेज हाम्री अत्यन्त मिलनसार,मृदुभाषी, कर्तब्यपरायण,सहनशील र
               एउटा अषल मानव जातिको प्रतिनिधि पात्र यशोदा निरौला जसको मिति 2072
               जेष्ठ 07 गते प्रसुति उपचारका क्रममा अकल्पनीय श्वर्गारोहण भयो।
               हामीले हामी सम्पुर्णका माझबाट एउटी पुजनीय महिला गुमाउनु परेको छ।
               उहाँका ती अमुल्य गुणहरुले हामीलाई अब कोहि पनी त्यस्ता आमाहरु हामी
               अल्पायूमा नै गुमाउनु नपरोस भनेर उहाँकै पुण्य नाममा एउटा मानव
               हितमा समर्पित "यशोदा निरौला स्मृति प्रतिष्ठान" नामक एउटा
               प्रतिष्ठान (fund) स्थापना गरेका छौ। उक्त संस्थाका सम्पुर्ण
               गतिविधिहरु यो website मार्फत यसै वेबपेजबाट अवगत गराउने उद्देष्य
               रहने छ।
            </p>
         </div>
      </div>
   );
}

export default Hero;
