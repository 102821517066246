import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import { BrowserRouter as Router } from "react-router-dom";
import RouterPage from "./components/layout/RouterPage";
import { PrathistanContextProvider } from "./components/context/PrathistanContext";
import ToastContainer from "./components/shared/ToastContainer";
function App() {
   return (
      <>
         <Router>
            <ToastContainer />
            <PrathistanContextProvider>
               <RouterPage />
            </PrathistanContextProvider>
         </Router>
      </>
   );
}

export default App;
