import React from "react";
import Hero from "./Hero";
import About from "./About";
import Members from "./Members";
import Events from "./Events";
import Contact from "./Contact";
import Gallery from "./Gallery";
import Map from "./Map";

function Home() {
   return (
      <>
         <Hero />
         <About />
         <Members />
         <Events />
         <Gallery />
         <Map />
         <Contact />
      </>
   );
}

export default Home;
