import React from "react";
// import naruto from "../../assets/img/naruto.jpeg";
import durga from "../../assets/img/durga.jpeg";
import nandikesar from "../../assets/img/nandikesar.jpeg";
import ramesh from "../../assets/img/ramesh.jpeg";
import nitaadhikari from "../../assets/img/nitaadhikari.jpeg";

const memberImages = {
   durga,
   nandikesar,
   ramesh,
   nitaadhikari,
   // Add more members here as needed
};

function Member_card({ member }) {
   return (
      <div className="member-card mx-2 text-center shadow-sm ">
         <div className="w-fit mx-auto">
            <img src={memberImages[member.img]} alt={member.name} />
         </div>
         <p className="name">{member.name}</p>
         <p className="status">{member.status}</p>
      </div>
   );
}

export default Member_card;
