import { React } from "react";
import logo from "../assets/img/logo.png";
import { Link } from "react-router-dom";
import Scroll from "../utils/Scroll";

function Header() {
   //calling the imported scroll function
   Scroll();

   return (
      <div id="header" className="sticky-top   ">
         <nav className="navbar navbar-expand-lg p-2 bg-slate-500">
            <div className="container w-full sm:w-3/4 ">
               <div className="relative left-1/2 -translate-x-1/2 sm:left-0 sm:translate-x-0">
                  <Link to="/" className="navbar-brand">
                     <img src={logo} alt="" />
                  </Link>
               </div>
               <div className="relative  ">
                  <div className="" id="">
                     <ul className="flex gap-3">
                        <li className=" ">
                           <a
                              className=" no-underline about"
                              aria-current="page"
                           >
                              ABOUT
                           </a>
                        </li>
                        <li className="">
                           <a className=" no-underline members ">MEMBERS</a>
                        </li>
                        <li className="">
                           <a className="no-underline events">EVENTS</a>
                        </li>
                        <li className="">
                           <a className="no-underline contact">CONTACT</a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </nav>
      </div>
   );
}

export default Header;
