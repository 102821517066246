import React from "react";
import Member_card from "./shared/Member_card";
function Members() {
   let members = [
      { name: "नन्दीकेशर निरौला", img: "nandikesar", status: "अध्यक्ष" },
      { name: "दुर्गा निरौला", img: "durga", status: "उपाध्यक्ष" },
      { name: "रमेश निरौला", img: "ramesh", status: "सचिब" },
      { name: "निता अधिकारि निरौला", img: "nitaadhikari", status: "कोषाध्यक्ष" },
   ];
   //   for (let i = 1; i <= 5; i++) {
   //     members.push(<Member_card />);
   //   }

   return (
      <div id="members">
         <div className="section-title">Members</div>
         <div className="container members">
            {members.map((member) => (
               <Member_card member={member} />
            ))}
         </div>
      </div>
   );
}

export default Members;
