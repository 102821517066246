import React from "react";
import { createContext, useEffect, useReducer } from "react";
import axios from "axios";
import PrathistanReducer from "./PrathistanReducer";

import Toast from "../shared/Toast";

const PrathistanContext = createContext();

function PrathistanContextProvider({ children }) {
   const initialState = {
      events: [],
      event: [],
      singleEvent: [],
   };

   const [state, dispatch] = useReducer(PrathistanReducer, initialState);

   // @ desc   fetch single event
   // @ GET   api/events/:id
   // @ public
   const fetchEvent = async (id) => {
      try {
         const res = await axios.get(
            `${process.env.REACT_APP_PRATHISTAN_API}/events/${id}`
         );
         dispatch({ type: "event", payload: res.data.response });
      } catch (ex) {
         console.log(ex);
      }
   };

   // @ desc   fetch events
   // @ GET   api/events
   // @ public
   const fetchEvents = async () => {
      try {
         const res = await fetch(
            `${process.env.REACT_APP_PRATHISTAN_API}/events`
         );
         dispatch({ type: "events", payload: await res.json() });
      } catch (ex) {
         console.log(ex);
      }
   };

   // @ desc   delete event
   // @ DELETE   api/events/:id
   // @ private
   const deleteEvent = async (id) => {
      try {
         const res = await axios.delete(
            `${process.env.REACT_APP_PRATHISTAN_API}/events/${id}`
         );
         console.log("respnse status", res.status);
         if (res.status === 204) {
            fetchEvents();
            Toast({ type: "success", message: "Event deleted successfully" });
         }
      } catch (ex) {
         console.log(ex);
      }
   };

   // @ desc   add event
   // @ POST   api/events
   // @ private
   const addEvent = async (data) => {
      try {
         console.log("post data", data);
         const res = await fetch(
            `${process.env.REACT_APP_PRATHISTAN_API}/events`,
            {
               method: "POST",
               headers: {
                  "Content-Type": "application/json",
               },
               body: JSON.stringify(data),
            }
         );
         console.log("response status", res.status);
         if (res.status === 201) {
            fetchEvents();
            Toast({ type: "success", message: "Event added successfully" });
            return true;
         }
      } catch (ex) {
         console.log(ex);
      }
   };

   // @ desc   update event
   // @ PUT   api/events/:id
   // @ private
   const updateEvent = async (id, data) => {
      try {
         const res = await fetch(
            `${process.env.REACT_APP_PRATHISTAN_API}/events/${id}`,
            {
               method: "PUT",
               headers: {
                  "Content-Type": "application/json",
               },
               body: JSON.stringify(data),
            }
         );
         console.log("response status", res.status);
         if (res.status === 200) {
            fetchEvents();
            Toast({ type: "success", message: "Event updated successfully" });
         }
      } catch (ex) {
         console.log(ex);
      }
   };

   useEffect(() => {
      fetchEvents();
   }, []);

   return (
      <PrathistanContext.Provider
         value={{ ...state, fetchEvent, deleteEvent, addEvent, updateEvent }}
      >
         {children}
      </PrathistanContext.Provider>
   );
}

export default PrathistanContext;
export { PrathistanContextProvider };
